<template>
  <div
    id="job-modal"
    ref="job-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            v-if="viewType === 'edit'"
            class="modal-title"
          >
            Job: {{ job.id }}
          </h5>
          <h5
            v-else
            class="modal-title"
          >
            Add New Job
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label class="form-label">Name:</label>
              <input
                v-model="job.name"
                :class="{'form-control': true, 'is-invalid': !job.name.length && nameBlured}"
                required
                type="text"
                @blur="nameBlured = true"
              />
              <div class="invalid-feedback">
                Please, provide a job name.
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Description:</label>
              <textarea
                v-model="job.description"
                class="form-control"
                rows="3"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Expression:</label>
              <input
                v-model="userExpression"
                :class="{ 'form-control': true, 'is-invalid': !isValidCron() && expressionBlured }"
                placeholder="0 0 12 * * ?"
                required
                type="text"
                @blur="expressionBlured = true"
              />
              <div class="hint">
                {{ cronHint }}
              </div>
              <div class="invalid-feedback">
                Please, provide a valid cron expression.
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Service:</label>
              <select
                v-model="job.service"
                class="form-select"
                required
                @change="onServiceSelectChanged"
              >
                <option
                  v-for="(service, index) in services"
                  :key="index"
                  :value="service.key"
                >
                  {{ service.value }}
                </option>
              </select>
            </div>
            <div>
              <div
                v-if="isAdditionalConfigurationSectionVisible"
              >
                <SelectInput
                  v-if="isBrandSelectorVisible"
                  v-model="job.customParams.brand"
                  :options="brandSelectorOptions"
                  description="Brand for calculation"
                  label="Brand for calculation"
                  @change="startNowEnabled = false; inputsChanged = true"
                />
                <SelectInput
                  v-if="isShipheroSelectorVisible"
                  v-model="job.customParams.shiphero"
                  :options="shipheroSelectorOptions"
                  description="The “default” option means  that decision  about including shiphero in aggregation process will be made by system depends on requested date."
                  label="Include Shiphero in aggregation process?"
                  @change="startNowEnabled = false; inputsChanged = true"
                />
                <SelectInput
                  v-if="isTrailingPeriodSelectorVisible"
                  v-model="job.customParams.period"
                  :options="periodOptions"
                  description="Trailing period for calculation"
                  label="Trailing period for calculation"
                  @change="startNowEnabled = false; inputsChanged = true"
                />
              </div>
            </div>
            <div class="mb-3 form-check">
              <input
                v-model="job.active"
                :disabled="viewType!=='add'"
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label">Active</label>
            </div>
            <hr />
            <div
              v-if="isDateSelectorVisible"
              class="mb-3"
            >
              <label class="form-label">
                Start date:
                <br />
                <small class="fst-italic">
                  Leave this field unfilled if You want to start from the Last Fetch Date for the group.
                </small>
                <br />
                <small>
                  Job Group: <b>{{ job.serviceGroup }}</b>;
                  Last Fetch Date: <b>{{ job.lastGroupFetch ? job.lastGroupFetch.split('T')[0] : 'Not Defined' }}</b>
                </small>
              </label>
              <input
                v-model="job.startDate"
                :class="{'form-control': true}"
                required
                type="date"
                @change="startNowEnabled = false; inputsChanged = true"
              />
            </div>
            <div
              v-if="isDateSelectorVisible"
              class="mb-3"
            >
              <label class="form-label">End date:</label>
              <input
                v-model="job.endDate"
                :class="{'form-control': true}"
                required
                type="date"
                @change="startNowEnabled = false; inputsChanged = true"
              />
            </div>
            <div
              v-if="inputsChanged"
              class="mb-3 form-check"
            >
              <input
                id="updateBeforeStartCheckbox"
                v-model="startNowEnabled"
                class="form-check-input"
                required
                type="checkbox"
              />
              <label
                class="form-check-label text-warning"
                for="updateBeforeStartCheckbox"
              >I am aware that the job will be updated before start</label>
            </div>
          </form>
        </div>
        <div
          v-if="viewType === 'edit'"
          class="modal-footer d-flex flex-row justify-content-between"
        >
          <div class="d-flex justify-content-start">
            <button
              aria-label="Close"
              class="btn btn-sm btn-info mx-1"
              data-bs-dismiss="modal"
              type="button"
              @click="$router.push(`/logs/${job.id}`)"
            >
              Logs
            </button>
            <button
              aria-label="Close"
              class="btn btn-sm btn-danger mx-1"
              data-bs-dismiss="modal"
              type="button"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              :disabled="startNowEnabled === false"
              aria-label="Close"
              class="btn btn-sm btn-warning mx-1"
              type="button"
              @click="startJob"
            >
              Start Now
            </button>
          </div>
          <div class="d-flex justify-content-end">
            <button
              v-if="!job.active"
              aria-label="Close"
              class="btn btn-sm btn-success mx-1"
              type="button"
              @click="changeStatus('activate')"
            >
              Activate
            </button>
            <button
              v-if="job.active"
              aria-label="Close"
              class="btn btn-sm btn-secondary mx-1"
              type="button"
              @click="changeStatus('deactivate')"
            >
              Deactivate
            </button>
            <button
              aria-label="Close"
              class="btn btn-sm btn-primary mx-1"
              type="button"
              @click="updateJob"
            >
              Update
            </button>
          </div>
        </div>
        <div
          v-if="viewType === 'add'"
          class="modal-footer"
        >
          <button
            aria-label="Close"
            class="btn btn-success"
            type="submit"
            @click="addNewJob"
          >
            Add New Job
          </button>
        </div>
      </div>
    </div>
  </div>
  <DeleteConfirmationModal
    ref="job-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      <h6>
        <i class="bi bi-exclamation-triangle" />
        Do you really want to delete this job?
        This operation can not be undone!
      </h6>
    </template>
  </DeleteConfirmationModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { Modal } from 'bootstrap';
import { modalViewTypes } from '@/components/constants';

import SelectInput from '@/components/common/SelectInput';
import {
  aggregationJobs,
  configurableJobs,
  forecastJobs,
  forecastJobsHash,
  shipheroCalculationOptions,
  slaJobs,
  trailingPeriodCalculationOptions,
  transimpactJobs
} from '@/components/Jobs/constants';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal.vue';
import { convertCronToUserTime, convertLocalTimeCronToUTC } from '@/components/Jobs/utils';

const defaultJob = {
  id: null,
  name: '',
  description: '',
  expression: '',
  service: '',
  active: false,
  startDate: null,
  endDate: null,
  customParams: {
    brand: null,
    shiphero: null,
    period: 'week'
  },
  brand: null
};

export default {
  name: 'JobModal',
  components: {
    DeleteConfirmationModal,
    SelectInput
  },
  emits: ['tableForceUpdate'],
  data: () => ({
    editModal: null,
    confirmationModalDelete: null,
    job: {
      id: null,
      name: '',
      description: '',
      expression: '',
      service: '',
      active: false,
      startDate: null,
      endDate: null,
      customParams: {
        brand: null,
        shiphero: null,
        period: 'week'
      },
      brand: null
    },
    nameBlured: false,
    expressionBlured: false,
    serviceBlured: false,
    startNowEnabled: true,
    stopEnabled: true,
    inputsChanged: false,
    periodOptions: trailingPeriodCalculationOptions,
    userExpression: null,
    expression: null
  }),
  computed: {
    ...mapGetters({
      services: 'service/services',
      companyTypeMappings: 'companyTypeMapping/companyTypeMappingItems'
    }),
    isAdditionalConfigurationSectionVisible() {
      return configurableJobs.includes(this.job.service);
    },
    isSlaServiceSelected() {
      return slaJobs.includes(this.job.service);
    },
    brandSelectorOptions() {
      let effectiveCompanyTypeMappings = this.companyTypeMappings;
      if (this.isSlaServiceSelected) {
        effectiveCompanyTypeMappings = _.filter(effectiveCompanyTypeMappings, (m) => !!m.companyCode);
      }

      const companies = _.uniq(_.map(effectiveCompanyTypeMappings, 'company'));
      return [
        { key: null, value: 'All Brands' },
        ..._.map(companies, (c) => ({ key: c, value: c }))
      ];
    },
    shipheroSelectorOptions() {
      return shipheroCalculationOptions;
    },
    isShipheroSelectorVisible() {
      return aggregationJobs.includes(this.job.service);
    },
    isDateSelectorVisible() {
      return !transimpactJobs.includes(this.job.service)
        && !forecastJobs.includes(this.job.service)
        && 'LOW_SLA_ALERT' !== this.job.service;
    },
    isBrandSelectorVisible() {
      return !forecastJobs.includes(this.job.service);
    },
    isTrailingPeriodSelectorVisible() {
      return forecastJobsHash.FORECAST_MISMATCH === this.job.service;
    },
    viewType() {
      return this.job.id ? modalViewTypes.edit : modalViewTypes.add;
    },
    cronHint() {
      if (!this.userExpression) {
        return 'Enter a cron expression. E.g., 0 0 12 * * ?';
      } else if (!this.isValidCron()) {
        return 'Invalid cron expression. Please check the format.';
      } else {
        this.handleUserToUTCExpression();
        return 'Cron expression for UTC time zone: ' + this.job.expression;
      }
    }
  },
  async mounted() {
    this.editModal = new Modal(this.$refs['job-modal']);
    this.confirmationModalDelete = this.$refs['job-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete.hide();
    await this.loadServices();
    await this.companyTypeMappingFetchAll();
  },
  methods: {
    ...mapActions({
      schedulerCreateJob: 'scheduler/createJob',
      schedulerFetchJob: 'scheduler/fetchJob',
      schedulerUpdateJob: 'scheduler/updateJob',
      schedulerUpdateJobStatus: 'scheduler/updateJobStatus',
      schedulerStartJob: 'scheduler/startJob',
      schedulerDeleteJob: 'scheduler/deleteJob',
      servicesFetchAll: 'service/fetchServices',
      companyTypeMappingFetchAll: 'companyTypeMapping/fetchCompanyTypeMappingItems'
    }),
    onServiceSelectChanged() {
      // additional configuration options should be set to default
      // after the service select changed
      if (this.job.customParams.shiphero || this.job.customParams.brand) {
        this.job.customParams.shiphero = null;
        this.job.customParams.brand = null;
      }
    },
    async initModal(id) {
      this.job.id = id;
      if (this.job.id) {
        await this.loadJob();
        this.handleUserExpression();
      } else {
        this.job = { ...defaultJob };
        this.userExpression = null;
      }
      this.startNowEnabled = true;
      this.inputsChanged = false;
      this.editModal.show();
    },
    async loadServices() {
      try {
        await this.servicesFetchAll();
        this.job.service = this.services[0]?.key;
      } catch {
        this.$toast.show('Failed to fetch services', { type: 'error' });
      }
    },
    isValidCron() {
      const regex = /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every \d+(ns|us|µs|ms|s|m|h)+)|^((\d+|\*|\/|-|,|\?)\s+){4,6}(\d+|\*|\/|-|,|\?|([A-Z]{3}(-[A-Z]{3})?))$/;
      return regex.test(this.userExpression);
    },
    validateForm() {
      this.nameBlured = true;
      this.expressionBlured = true;
      this.serviceBlured = true;
      return (!!this.job.name && this.isValidCron());
    },
    async addNewJob() {
      const isValidForm = this.validateForm();
      if (!isValidForm) {
        this.$toast.show('Failed to add the job.', { type: 'error' });
        return;
      }
      try {
        this.handleUserToUTCExpression();
        const dataToSave = { ...this.job };
        if (!this.isAdditionalConfigurationSectionVisible) {
          delete dataToSave.customParams;
        }
        await this.schedulerCreateJob(dataToSave);
        this.$toast.show('The new job has been added.', { type: 'success' });
        this.$emit('tableForceUpdate');
        this.editModal.hide();
      } catch (e) {
        if (e.cause) {
          this.$toast.show('New job data is incorrect. Please, try check fields.', { type: 'error' });
          return;
        }
        this.$toast.show('Failed to add new job.', { type: 'error' });
      }
    },
    async loadJob() {
      try {
        this.job = await this.schedulerFetchJob(this.job.id);
        this.expression = this.job.expression;
      } catch {
        this.$toast.show('Failed to fetch the job.', { type: 'error' });
      }
    },
    showDeleteConfirmationModalHandler() {
      this.editModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.schedulerDeleteJob(this.job.id);
        this.$emit('tableForceUpdate');
        this.$toast.show('The job has been deleted.', { type: 'success' });
        this.editModal.hide();
      } catch {
        this.$toast.show('Failed to delete the job.', { type: 'error' });
      }
    },
    async updateJob() {
      try {
        this.handleUserToUTCExpression();
        const dataToSave = { ...this.job };
        if (!this.isAdditionalConfigurationSectionVisible) {
          delete dataToSave.customParams;
        }
        await this.schedulerUpdateJob(dataToSave);
        this.$emit('tableForceUpdate');
        this.$toast.show('The job has been updated.', { type: 'success' });
        this.editModal.hide();
      } catch (e) {
        if (e.cause) {
          this.$toast.show('Failed to update the job. Please, try check fields.', { type: 'error' });
          throw e;
        }
        this.$toast.show('Failed to update the job.', { type: 'error' });
        throw e;
      }
    },
    async changeStatus(status) {
      try {
        await this.schedulerUpdateJobStatus({ jobId: this.job.id, status });
        this.active = status;
        this.$emit('tableForceUpdate');
        this.$toast.show('The job status has been updated.', { type: 'success' });
        this.editModal.hide();
      } catch (e) {
        this.$toast.show('Failed to change the status of the job.', { type: 'error' });
      }
    },
    async startJob() {
      if (this.inputsChanged) {
        await this.updateJob();
      }
      try {
        await this.schedulerStartJob(this.job.id);
        this.$emit('tableForceUpdate');
        this.$toast.show('The job has been started.', { type: 'success' });
        this.editModal.hide();
      } catch {
        this.$toast.show('Failed to start the job.', { type: 'error' });
      }
    },
    handleUserExpression() {
      this.userExpression = convertCronToUserTime(this.expression);
    },
    handleUserToUTCExpression() {
      this.job.expression = convertLocalTimeCronToUTC(this.userExpression);
    }
  }
};
</script>
<style scoped>
.hint {
  margin-top: 0.5rem;
  color: grey;
  font-size: 0.9rem;
}
</style>
